export const colors = [
  "#c00000",
  "#aaaaaa",
  "#bf6d6d",
  "#444444",
  "#9e3434",
  "#dddddd",
  "#c00000",
  "#aaaaaa",
  "#bf6d6d",
  "#444444",
  "#9e3434",
  "#dddddd",
  "#c00000",
  "#aaaaaa",
  "#bf6d6d",
  "#444444",
  "#9e3434",
  "#dddddd",
  "#c00000",
  "#aaaaaa",
  "#bf6d6d",
  "#444444",
  "#9e3434",
  "#dddddd",
]
